import React, { useEffect, useState } from "react"; // , { useCallback, useEffect, useRef, useState }
// import styled from "styled-components";
import Reactmarkdown from "react-markdown";
// import moment from "moment";
import { Job } from "../utils/types";
import FootnoteText from "./FootnoteText";
// import { Button } from "./Button";
import { OutboundLinkStyles } from "./LinkStyles";
// import { animated, useSpring } from "react-spring";
import { MarkDownResetStyles } from "./MarkdownResetStyles";
import Spacer from "./Spacer";

const JobPost = ({ job }: { job: Job }): JSX.Element => {
  const [applyToEmailAddress, setApplyToEmailAddress] = useState("");

  useEffect(() => {
    // This might help ever so slightly to hide the email address from bots
    // (at least those who only search for mailto links)
    setApplyToEmailAddress(job.applyToEmailAddress);
  }, [job.applyToEmailAddress]);

  return (
    <MarkDownResetStyles>
      <h3 tabIndex={0}>{job.jobTitle}</h3>
      {job.location && <FootnoteText tabIndex={0}>{job.location}</FootnoteText>}
      <Spacer />
      {/* <animated.div
        ref={accordionRef}
        style={{
          height: props.height,
          overflow: "hidden",
        }}
      > */}
      {/* <DescriptionTextStyles isOpen={isOpen}>
          {job.description}
        </DescriptionTextStyles> */}
      <div tabIndex={0}>
        <Reactmarkdown>{job.description}</Reactmarkdown>
      </div>
      {job.aboutTheRole && (
        <div tabIndex={0}>
          {/* <HiddenContentContainer isOpen={isOpen}>
              <h4 tabIndex={0}>About the role</h4>
            </HiddenContentContainer> */}
          <h4>About the role</h4>
          <Reactmarkdown>{job.aboutTheRole}</Reactmarkdown>
        </div>
      )}
      {job.aboutYou && (
        <div tabIndex={0}>
          <h4>About you</h4>
          <Reactmarkdown>{job.aboutYou}</Reactmarkdown>
        </div>
      )}
      <div tabIndex={0}>
        <h4>How to apply</h4>
        <p>
          To apply, send over your CV and portfolio to{" "}
          <OutboundLinkStyles
            href={applyToEmailAddress ? `mailto:${applyToEmailAddress}` : "/"}
            aria-label="Clickable link to apply to job email address"
            target="_blank"
            rel="noopener noreferrer"
          >
            {applyToEmailAddress || "our email address"}
          </OutboundLinkStyles>
        </p>
      </div>

      {/* {job.closingDate && (
               <FootnoteText>
                 Closing date: {moment(job.closingDate).format("DD/MM/YYYY")}
               </FootnoteText>
             )} */}
      {/* </animated.div> */}
    </MarkDownResetStyles>
  );
};

// const DescriptionTextStyles = styled(Reactmarkdown)<{ isOpen: boolean }>`
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: ${(props) =>
//     props.isOpen ? "none" : 3}; /* number of lines to show */
//   -webkit-box-orient: vertical;
// `;
// const HiddenContentContainer = styled.div<{ isOpen: boolean }>`
//   opacity: ${(props) =>
//     props.isOpen
//       ? "1"
//       : "0"}; /* push content out of view while retaining animation */
// `;

export default JobPost;
