import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { Job } from "../utils/types";
import JobPost from "../components/JobPost";
import { ItemSeparator } from "../components/ItemSeparator";
import Spacer from "../components/Spacer";
import { PageOuter } from "../components/PageOuter";
import PageIntro from "../components/PageIntro";
import { ArrowLink } from "../components/ArrowLink";

const CareersPage = ({
  location,
}: {
  location: LocationState;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const title = "Careers";
  return (
    <Layout location={location}>
      <SEO title={title} />
      <PageOuter title={title} midLayout>
        <StaticQuery
          query={query}
          render={(data) => {
            if (!data) {
              return null;
            }
            return (
              <>
                <PageIntro
                  title="Join our team"
                  intro="Unashamedly geeky and proud of it."
                />
                {data.allStrapiJobs && data.allStrapiJobs.totalCount > 0 ? (
                  data.allStrapiJobs.nodes.map((job: Job, index: number) => {
                    const lastItem =
                      index === data.allStrapiJobs.nodes.length - 1;
                    return (
                      <div key={job.id} data-aos="fade" data-aos-delay="250">
                        <JobPost
                          job={job}
                          // showMore={showMore}
                          // toggleShowMore={toggleShowMore}
                        />
                        {/* {!lastItem && <Spacer height={2} />} */}
                        {!lastItem && (
                          <>
                            <Spacer height={2} />
                            <ItemSeparator />
                            <Spacer height={2} />
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div data-aos="fade" data-aos-delay="250">
                    <h4 tabIndex={0}>
                      Please check back later, we are not currently recruiting
                    </h4>
                    <Spacer height={2} />
                    <ArrowLink
                      arrowLeft
                      to="/"
                      ariaLabel="Clickable link to the home page"
                    >
                      Home
                    </ArrowLink>
                  </div>
                )}
              </>
            );
          }}
        />
      </PageOuter>
    </Layout>
  );
};
const query = graphql`
  query {
    # filter out the dummy blank job, used to prevent Gatsby crash when no other Jobs set in Strapi
    # allStrapiJobs(filter: { jobTitle: { ne: "BlankJob" } }) {
    allStrapiJobs {
      nodes {
        id
        jobTitle
        location
        description
        aboutTheRole
        aboutYou
        applyToEmailAddress
        # closingDate
      }
      totalCount
    }
  }
`;

export default CareersPage;
